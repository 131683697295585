<template>
    <div class="wrap">
        <section class="banner"></section>
        <section class="projects">
            <div class="content-container">
                <div class="tabs-wrap">
                    <div class="tabs" v-if="announcementTabs.length">
                        <span :class="[selectTabId === tab.id ? 'on' : '']" v-for="tab in announcementTabs" :key="tab.id" @click="toogleTab(tab.id)">{{tab.name}}</span>
                    </div>
                </div>
                <div class="main">
                    <div class="left">
                        <div class="item" v-for="item in announcementList" :key="item.id" @click="goAnnouncementDetail(item.id)">
                            <el-image style="width:160px; height:100px" fit="cover" :src="item.cover" lazy>
                                <template #placeholder>
                                <div class="image-slot">
                                    <img :src="defaultImg" alt="" width="160" height="100"  />
                                </div>
                            </template>
                            <template #error>
                                <div class="image-slot">
                                    <img :src="defaultImg" alt="" width="160" height="100"  />
                                </div>
                            </template>
                            </el-image>
                            <div class="info">
                                <div class="title">{{item.title}}</div>
                                <div class="content">{{item.summary}}</div>
                                <div class="tips">
                                    <span class="tip">发布时间：<span>{{item.publish_at}}</span></span>
                                    <span class="tip">地域：<span>{{item.position}}</span></span>
                                    <span class="tip">采购人：<span>{{item.buyer}}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <Shortcut :latestList="latestList" />
                        <!-- <Shortcut /> -->
                    </div>
                </div>
                <el-pagination
                    class="my-pagination"
                    v-model:currentPage="pageNum"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </section>
    </div>
</template>
<script>
import {
    getCurrentInstance,
    reactive,
    watchEffect,
    inject,
    computed,
    toRefs
} from 'vue'
import {useRouter} from 'vue-router'
import { useStore } from 'vuex'
import Shortcut from '@/components/Shortcut'
export default {
    name: 'project',
    components: {
        Shortcut
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const router = useRouter()
        const store = useStore()
        const state = reactive({
            announcementList: [],
            pageSize: 0,
            pageNum: 1,
            total: 0,
            latestList: [],
            defaultImg: require('../../assets/image/announcement-image.png')
        })
        const selectTabId = computed(() => {
            const { announcementTabId } = store.state
            if(!isNaN(announcementTabId)) getList(announcementTabId)
            return announcementTabId
        })

        const announcementTabs = inject('announcementTabs')

    
        proxy.$api.announcement.getLatest().then(res => {
            state.latestList = res.list && res.list.length ? res.list : []
        })

        watchEffect(() => {
            if(!announcementTabs?.value?.length) return
            if(!isNaN(selectTabId.value)) return
            store.commit("setAnnouncementTabId", announcementTabs.value[0].id)
        })


        const toogleTab = (id) => {
            store.commit("setAnnouncementTabId", id)
            getList(id)
        }

        // const getCurrentAnnouncement = () => {
        //     getList(selectTabId)
        // }

        const goAnnouncementDetail = (id) => {
            router.push({path: '/projectCenter/projectDetail', query: { id }})
        }


        function getList(id) {
            proxy.$api.article.getList(id, state.pageNum).then(res => {

                state.announcementList = res.data && res.data.length ? res.data : []
                state.total = res.total
                state.pageSize = res.pageSize
            })
        }

        return {
            ...toRefs(state),
            selectTabId,
            announcementTabs,
            goAnnouncementDetail,
            toogleTab
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>
<template>
    <div class="shortcuts">
        <div class="cate">最新公告</div>
        <div class="item" v-for="item in latestList" :key="item" @click="goAnnouncementDetail(item.id)">{{item.title}}</div>
    </div>
</template>
<script>
import {
    // getCurrentInstance,
    reactive,
    toRefs
} from 'vue'
import { useRouter } from 'vue-router'
export default {
    name: 'shortcut',
    props: {
        latestList: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        const router = useRouter()
        const state = reactive({
            
        })

        const goAnnouncementDetail = (id) => {
            router.push({path: '/projectCenter/projectDetail', query: { id }})
        }   

        return {
            ...toRefs(state),
            ...props,
            goAnnouncementDetail
        }

    }
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>